import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Container from '../components/Container'
import { PageHeader } from '../types/definitions'
import { Box, Styled } from 'theme-ui'
import { MDXRenderer } from 'gatsby-plugin-mdx'

type ArticlePageProps = {
  data: {
    page: {
      frontmatter: {
        header: PageHeader
        footer: {
          showCTA: boolean
        }
        seo: { title: string; description: string }
      }
      body: string
    }
  }
}

const ArticlePage: FunctionComponent<ArticlePageProps> = ({ data }) => {
  const { header, seo, footer } = data.page.frontmatter
  return (
    <Layout
      header={{ boxFeaturedImage: true, ...header }}
      footer={footer}
      seo={seo}
    >
      <Styled.root>
        <Container bg="white" extraSx={{ pt: [0, 0] }}>
          <MDXRenderer>{data.page.body}</MDXRenderer>
        </Container>
      </Styled.root>
    </Layout>
  )
}

export default ArticlePage

export const pageQuery = graphql`
  query Article($id: String!) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        footer {
          showCTA
        }
        ...header
        seo {
          description
          title
        }
      }
      body
    }
  }
`
